<template>
  <div class="companies-container">
    <PaginationTable
      :itemsInPage="itemsPageCount"
      :listClass="'companies-list'"
      :itemClass="'company-area'"
      :getTotalCount="itemsCount"
      :getPageContent="fetchPage"
      :addItemsFull="false"
    >
      <template v-slot:header> </template>
      <template v-slot:document="{ curItem }">
        <div class="company-heading">
          <div class="left=part">
            <p class="no-online-text" v-if="!curCountry.online">
              {{ translations("NoOnlineSearchInCountry") }}
            </p>
            <p class="grey inn" v-if="curItem.online">{{ curItem.inn }}</p>
            <h4 class="name" v-if="curCountry.online">{{ curItem.name }}</h4>
            <p class="attribute address" v-if="curItem.address">
              <span class="icon"
                ><svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16.175L12.7125 12.4625C13.4467 11.7283 13.9466 10.7928 14.1492 9.77441C14.3517 8.75602 14.2477 7.70045 13.8503 6.74117C13.4529 5.78189 12.78 4.96198 11.9167 4.38513C11.0533 3.80827 10.0383 3.50038 9 3.50038C7.96167 3.50038 6.94666 3.80827 6.08332 4.38513C5.21997 4.96198 4.54706 5.78189 4.14969 6.74117C3.75231 7.70045 3.64831 8.75602 3.85084 9.77441C4.05337 10.7928 4.55333 11.7283 5.2875 12.4625L9 16.175ZM9 18.296L4.227 13.523C3.28301 12.579 2.64014 11.3763 2.3797 10.0669C2.11925 8.75752 2.25293 7.40032 2.76382 6.16693C3.27472 4.93353 4.13988 3.87933 5.24991 3.13764C6.35994 2.39594 7.66498 2.00006 9 2.00006C10.335 2.00006 11.6401 2.39594 12.7501 3.13764C13.8601 3.87933 14.7253 4.93353 15.2362 6.16693C15.7471 7.40032 15.8808 8.75752 15.6203 10.0669C15.3599 11.3763 14.717 12.579 13.773 13.523L9 18.296ZM9 10.25C9.39783 10.25 9.77936 10.092 10.0607 9.81066C10.342 9.52936 10.5 9.14783 10.5 8.75C10.5 8.35218 10.342 7.97065 10.0607 7.68934C9.77936 7.40804 9.39783 7.25 9 7.25C8.60218 7.25 8.22065 7.40804 7.93934 7.68934C7.65804 7.97065 7.5 8.35218 7.5 8.75C7.5 9.14783 7.65804 9.52936 7.93934 9.81066C8.22065 10.092 8.60218 10.25 9 10.25ZM9 11.75C8.20435 11.75 7.44129 11.4339 6.87868 10.8713C6.31607 10.3087 6 9.54565 6 8.75C6 7.95435 6.31607 7.19129 6.87868 6.62868C7.44129 6.06607 8.20435 5.75 9 5.75C9.79565 5.75 10.5587 6.06607 11.1213 6.62868C11.6839 7.19129 12 7.95435 12 8.75C12 9.54565 11.6839 10.3087 11.1213 10.8713C10.5587 11.4339 9.79565 11.75 9 11.75Z"
                    fill="#7D7E8B"
                  />
                </svg>
              </span>
              {{ curItem.address }}
            </p>
            <p class="attribute country" v-if="curItem.country">
              <span class="icon">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.15683 4.802C3.30686 5.6842 2.7865 6.8321 2.68321 8.05278C2.57992 9.27346 2.90001 10.4925 3.58967 11.5049C4.27932 12.5174 5.29651 13.2616 6.47024 13.6124C7.64397 13.9632 8.90269 13.8993 10.0348 13.4313C10.1115 12.9313 9.9435 12.4513 9.87483 12.2833C9.7215 11.91 9.21616 11.272 8.37283 10.3873C8.1475 10.1507 8.16216 9.96867 8.24283 9.42934L8.2515 9.36867C8.30616 8.99934 8.39816 8.78067 9.6415 8.58334C10.2735 8.48334 10.4395 8.73534 10.6695 9.08534L10.7468 9.2C10.9655 9.52 11.1275 9.59334 11.3722 9.704C11.4822 9.754 11.6188 9.81734 11.8022 9.92067C12.2368 10.1693 12.2368 10.45 12.2368 11.0647V11.1347C12.2368 11.3953 12.2115 11.6247 12.1715 11.824C12.6831 11.1819 13.0396 10.4305 13.2132 9.62813C13.3869 8.82577 13.373 7.99415 13.1727 7.19802C12.9724 6.4019 12.5911 5.66274 12.0584 5.03809C11.5257 4.41345 10.856 3.92018 10.1015 3.59667C9.73283 3.84534 9.22683 4.198 9.05016 4.44C8.96016 4.56334 8.83216 5.19467 8.41683 5.24667C8.30883 5.26 8.16283 5.25067 8.00816 5.24067C7.5935 5.214 7.02683 5.17734 6.8455 5.67C6.73016 5.982 6.71016 6.83 7.08283 7.27C7.14283 7.34 7.15416 7.47 7.1135 7.616C7.06016 7.80734 6.95283 7.924 6.91883 7.948C6.85483 7.91067 6.72683 7.762 6.6395 7.66134C6.43083 7.418 6.1695 7.11467 5.83216 7.02134C5.7095 6.98734 5.57483 6.95934 5.4435 6.93134C5.0775 6.85467 4.6635 6.76734 4.56683 6.562C4.49616 6.41134 4.49683 6.204 4.49683 5.98534C4.49683 5.70734 4.49683 5.39334 4.36083 5.08867C4.31355 4.97994 4.24406 4.8823 4.15683 4.802ZM8.00016 15.1667C4.31816 15.1667 1.3335 12.182 1.3335 8.5C1.3335 4.818 4.31816 1.83334 8.00016 1.83334C11.6822 1.83334 14.6668 4.818 14.6668 8.5C14.6668 12.182 11.6822 15.1667 8.00016 15.1667Z"
                    fill="#7D7E8B"
                  />
                </svg>
              </span>
              {{ curItem.country }}
            </p>
            <p class="attribute type" v-if="curItem.type">
              <span class="icon">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 17H3C2.80109 17 2.61032 16.921 2.46967 16.7803C2.32902 16.6397 2.25 16.4489 2.25 16.25V2.75C2.25 2.55109 2.32902 2.36032 2.46967 2.21967C2.61032 2.07902 2.80109 2 3 2H15C15.1989 2 15.3897 2.07902 15.5303 2.21967C15.671 2.36032 15.75 2.55109 15.75 2.75V16.25C15.75 16.4489 15.671 16.6397 15.5303 16.7803C15.3897 16.921 15.1989 17 15 17ZM14.25 15.5V3.5H3.75V15.5H14.25ZM6 5.75H12V7.25H6V5.75ZM6 8.75H12V10.25H6V8.75ZM6 11.75H9.75V13.25H6V11.75Z"
                    fill="#7D7E8B"
                  />
                </svg>
              </span>
              {{ curItem.type }}
            </p>
            <div class="purposes" v-if="typePurposesLoaded">
              <button
                type="button"
                @click="
                  choosePurpose({ purposeId: item.id, companyId: curItem.id })
                "
                class="btn btn-white-blue btn-big"
                v-for="item in listTypePurposes"
                :key="item.id"
              >
                {{ item.name }}
                {{ translations("PriceFromEuro", [item.minPrice]) }}
              </button>
            </div>
          </div>
          <div class="right-part">
            <span class="status">
              <svg
                v-if="curItem.status === 'opened'"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99967 14.6667C4.31767 14.6667 1.33301 11.682 1.33301 8C1.33301 4.318 4.31767 1.33333 7.99967 1.33333C11.6817 1.33333 14.6663 4.318 14.6663 8C14.6663 11.682 11.6817 14.6667 7.99967 14.6667ZM7.99967 13.3333C9.41416 13.3333 10.7707 12.7714 11.7709 11.7712C12.7711 10.771 13.333 9.41448 13.333 8C13.333 6.58551 12.7711 5.22895 11.7709 4.22876C10.7707 3.22856 9.41416 2.66666 7.99967 2.66666C6.58519 2.66666 5.22863 3.22856 4.22844 4.22876C3.22824 5.22895 2.66634 6.58551 2.66634 8C2.66634 9.41448 3.22824 10.771 4.22844 11.7712C5.22863 12.7714 6.58519 13.3333 7.99967 13.3333ZM7.33501 10.6667L4.50634 7.838L5.44901 6.89533L7.33501 8.78133L11.1057 5.00999L12.049 5.95266L7.33501 10.6667Z"
                  fill="#00BA1E"
                />
              </svg>
              <svg
                v-if="curItem.status === 'closing'"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99998 1.33337C11.68 1.33337 14.6666 4.32004 14.6666 8.00004C14.6666 11.68 11.68 14.6667 7.99998 14.6667C4.31998 14.6667 1.33331 11.68 1.33331 8.00004C1.33331 4.32004 4.31998 1.33337 7.99998 1.33337ZM7.99998 13.3334C10.9466 13.3334 13.3333 10.9467 13.3333 8.00004C13.3333 5.05337 10.9466 2.66671 7.99998 2.66671C5.05331 2.66671 2.66665 5.05337 2.66665 8.00004C2.66665 10.9467 5.05331 13.3334 7.99998 13.3334ZM10.3573 4.70004L11.3 5.64271L7.99998 8.94271L7.05731 8.00004L10.3573 4.70004Z"
                  fill="#E8C300"
                />
              </svg>
              <svg
                v-if="curItem.status === 'closed'"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4V14.4ZM7.2 10.4H8.8V12H7.2V10.4ZM7.2 4H8.8V8.8H7.2V4Z"
                  fill="#DB0300"
                />
              </svg>
              <span v-if="curItem.status === 'opened'" :class="curItem.status"
                >{{ translations("CompanyNotClosed") }}
                {{
                  curDate | date("date", $cookies.get("user_language"))
                }}</span
              >
              <span v-if="curItem.status === 'closing'" :class="curItem.status"
                >{{ translations("CompanyClosing") }}
                {{
                  curDate | date("date", $cookies.get("user_language"))
                }}</span
              >
              <span
                v-if="curItem.status === 'closed'"
                :class="curItem.status"
                >{{ translations("CompanyClosed") }}</span
              >
            </span>
            <span
              class="link open-products"
              :class="{ expanded: curItem.productsExpanded }"
              @click="expandCompany(curItem.id)"
              ><span v-if="!curItem.productsExpanded">{{
                translations("ShowAllProductsList")
              }}</span
              ><span v-if="curItem.productsExpanded">{{
                translations("Shrink")
              }}</span
              ><svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.9998 9.00056L11.1991 5.8L12.7998 7.39971L7.9998 12.2L3.1998 7.39971L4.80056 5.8L7.9998 9.00056Z"
                  fill="#4C69E8"
                />
              </svg>
            </span>
          </div>
        </div>
        <transition
          @enter="transitionStep1"
          @after-enter="transitionStep2"
          @before-leave="transitionStep3"
          @after-leave="transitionStep4"
          name="faq-accordion"
        >
          <div class="product-area" v-if="curItem.productsExpanded">
            <ProductList :companyId="curItem.id" />
          </div>
        </transition>
      </template>
    </PaginationTable>

    <div
      class="company-area"
      v-for="company in listCompanies"
      :key="company.id"
      :class="[
        { 'no-online': !curCountry.online },
        { opened: company.productsExpanded },
      ]"
    ></div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL, pluralType } from "@/config";
//import Loader from "@/components/app/Loader";
import PaginationTable from "@/components/PaginationTable";
import ProductList from "@/components/egrul/search/ProductList";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CompaniesList",
  components: { ProductList, PaginationTable /*Loader*/ },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      curDate: new Date(),
      itemsPageCount: 1,
      page: 1,
      companyTitles: "",
      pluralType,
      loading: false,
    };
  },
  props: {
    searchQuery: String,
    fetchCallback: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "translations",
      "curCountry",
      "typePurposesLoaded",
      "listTypePurposes",
      "curTypePurpose",
      "listSettings",
      "productsLoaded",
      "listProducts",
      "listCompanies",
      "listCompaniesCount",
      "curCompany",
      "companiesLoaded",
      "ListLoggedUser",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchTypePurposes",
      "fetchProducts",
      "setCurTypePurpose",
      "toggleCompanyOpen",
      "setCurCompany",
    ]),
    /*
    addItem() {
      if (this.storedItems.length && this.items.length < this.itemsLoaded) {
        this.items.push(this.storedItems.pop());
      } else {
        clearInterval(this.addingInterval);
      }
    },
    */
    async itemsCount() {
      console.log("получаем кол-во компаний + сами компании");
      if (!this.companiesLoaded) {
        await this.fetchCallback(this.page);
      }
      return this.listCompaniesCount;
    },
    async fetchPage(page) {
      this.page = page;
      console.log("CompaniesPage: ", this.page);
      if (+this.page !== +page) {
        console.log("получаем сами компании");
        await this.fetchCallback(this.page);
      }
      return this.listCompanies;
    },

    async loadMore() {
      this.loading = true;
      this.page++;

      await this.fetchCallback(this.page);

      this.loading = false;
      /*
      this.storedItems = this.listCompanies
        .reverse()
        .slice(
          (this.page - 1) * this.itemsPageCount,
          this.page * this.itemsPageCount > this.listCompaniesCount
            ? this.listCompaniesCount
            : this.page * this.itemsPageCount
        );
      clearInterval(this.addingInterval);
      this.addingInterval = setInterval(this.addItem, this.itemDelay);
      */
    },
    expandCompany(companyId) {
      this.toggleCompanyOpen(companyId);
    },
    async choosePurpose({ purposeId, companyId }) {
      await this.setCurTypePurpose(purposeId);
      await this.setCurCompany(companyId);

      if (this.ListLoggedUser.boughts === 0) {
        this.$metrika.reachGoal("TypePurposeSelect", {
          typePurpose: this.curTypePurpose.name,
        });
      }

      this.$router.push("/search/select-type");
    },
    transitionStep1(el) {
      // set the block height at the moment of its appearance
      el.style.height = el.scrollHeight + "px";
    },

    transitionStep2(el) {
      // remove inline styles from the block after animation of its appearance
      el.style.height = "auto";
    },

    transitionStep3(el) {
      // set the height of the block at the beginning of its disappearance animation
      el.style.height = el.scrollHeight + "px";
    },

    transitionStep4(el) {
      // remove inline styles from the block after the animation of its disappearance
      el.style.height = el.scrollHeight + "px";
    },
  },
  created() {
    this.companyTitles = this.translations("PluralCompaniesTitles");
    this.companyTitles = JSON.parse(this.companyTitles.trim());
    this.itemsPageCount = +this.listSettings.companies_per_page;
  },
  mounted() {},
};
</script>