<template>
  <div class="contents-area search-contents">
    <div class="full-content">
      <div class="search-form-content" id="searchForm">
        <div class="container">
          <SearchForm
            @country-selected="refreshCountry"
            @go-searching="refreshSearch"
          />
        </div>
      </div>
      <div class="search-results">
        <div class="container">
          <div class="loading-data" v-if="!companiesLoaded">
            <div class="loader-cont">
              <Loader />
            </div>
          </div>
          <div class="full-data-loaded" v-if="companiesLoaded">
            <h1 v-if="!curCountry.online">
              {{ searchQuery }} {{ translations("InCountry") }}
              <router-link :to="'/country/' + curCountry.link_sef"
                ><img
                  class="small-flag"
                  :src="'//' + serverLink + curCountry.flag"
                  alt=""
                />&nbsp;{{ curCountry.text }}</router-link
              >
            </h1>
            <h1 v-if="curCountry.online">
              {{
                translations("FoundNCompaniesRequestInCountry", [
                  foundTitles[
                    pluralName(
                      listCompaniesCount,
                      $cookies.get("user_language")
                    )
                  ],
                  listCompaniesCount +
                    " " +
                    companyTitles[
                      pluralType(
                        listCompaniesCount,
                        $cookies.get("user_language")
                      )
                    ],
                  "`" + searchQuery + "`",
                ]) + " "
              }}
              <router-link :to="'/country/' + curCountry.link_sef"
                ><img
                  class="small-flag"
                  :src="'//' + serverLink + curCountry.flag"
                  alt=""
                />&nbsp;{{ curCountry.text }}</router-link
              >
            </h1>
            <div class="found-companies" v-if="listCompaniesCount">
              <CompaniesListPage
                :searchQuery="searchQuery"
                :fetchCallback="fetchCallback"
              />
            </div>
            <div
              class="nothing-found"
              v-if="curCountry.online && !listCompaniesCount"
            >
              <h2>{{ translations("WhyCompanyNotFound") }}</h2>
              <div class="cause">
                <h3>{{ translations("InvalidRequest") }}</h3>
                <p
                  class="grey"
                  v-html="translations('WhyCompanyNotFoundText')"
                ></p>
                <div class="purposes">
                  <button
                    v-scroll-to="{ el: '#searchForm', offset: -100 }"
                    type="button"
                    class="btn btn-blue btn-big"
                  >
                    {{ translations("CorrectQuery") }}
                  </button>
                </div>
              </div>
              <div class="cause">
                <h3>{{ translations("RestrictionOfFreeSearch") }}</h3>
                <p
                  class="grey"
                  v-html="translations('RestrictionOfFreeSearchText')"
                ></p>
                <div class="purposes" v-if="typePurposesLoaded">
                  <button
                    type="button"
                    class="btn btn-blue btn-big"
                    v-for="item in listTypePurposes"
                    :key="item.id"
                  >
                    {{ item.name }}
                    {{ translations("PriceFromEuro", [item.minPrice]) }}
                  </button>
                </div>
              </div>
              <div class="cause">
                <h3>{{ translations("AnyDoubts") }}</h3>
                <p class="grey">{{ translations("GetFreeConsultNow") }}</p>
                <div class="purposes">
                  <button type="button" class="btn btn-blue btn-big">
                    {{ translations("RequestCallback") }}
                  </button>
                  <button type="button" class="btn btn-blue btn-big">
                    {{ translations("WriteToChat") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL, pluralType, pluralName } from "@/config";
import Loader from "@/components/app/Loader";
import SearchForm from "@/components/app/forms/SearchForm";
import CompaniesListPage from "@/components/egrul/search/CompaniesListPage";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Search",
  components: { Loader, SearchForm, CompaniesListPage },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      pluralType,
      pluralName,
      companyTitles: [],
      foundTitles: [],
      searchQuery: "",
      showNothing: false,
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "curCountry",
      "isCountriesLoaded",
      "typePurposesLoaded",
      "listTypePurposes",
      "productsLoaded",
      "listProducts",
      "listCompanies",
      "listCompaniesCount",
      "companiesLoaded",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchTypePurposes",
      "fetchProducts",
      "fetchCompanies2",
      "setCompanyFromQuery",
      "clearCompanies",
    ]),
    async fetchCompaniesOnline(page) {
      await this.fetchCompanies2({
        lang: this.$cookies.get("user_language"),
        query: this.searchQuery,
        countryId: this.curCountry.id,
        countryCode: this.curCountry.code,
        page,
      });
    },
    async fetchCallback(page) {
      console.log("fetchCallback page: ", page);
      await this.fetchCompaniesOnline(page);
    },
    refreshCountry() {
      console.log("country-selected");
    },
    refreshSearch() {
      console.log("go-searching");
      this.searchQuery = decodeURIComponent(this.$route.query.query || "");
      this.clearCompanies();
      if (this.isCountriesLoaded) {
        this.fetchData();
      }
    },
    async fetchData() {
      //console.log("curCountry: ", this.curCountry.id);

      if (this.curCountry.online) {
        await this.fetchCompaniesOnline(1);
      } else {
        await this.fetchProducts({
          lang: this.$cookies.get("user_language"),
          curCountry: this.curCountry.id,
        });
        await this.setCompanyFromQuery(this.searchQuery);
      }
    },
  },
  watch: {
    curCountry() {
      this.clearCompanies();
      this.fetchData();
    },
  },
  async created() {
    this.companyTitles = this.translations("PluralCompaniesTitles");
    this.companyTitles = JSON.parse(this.companyTitles.trim());
    this.foundTitles = this.translations("PluralFoundCompanyTitles");
    this.foundTitles = JSON.parse(this.foundTitles.trim());
    await this.clearCompanies();
  },
  async mounted() {
    this.refreshSearch();
  },
};
</script>